import React, { useEffect, useState } from 'react';
import './App.css';
import LandingPage from './components/LandingPage';
import './translations/i18n';
import { eventTypeEnum, languageEnum } from './types';

function App() {
  const [eventType, setEventType] = useState<eventTypeEnum | undefined>();
  const [language, setLanguage] = useState<languageEnum | undefined>();
	const path = window.location.search
  const urlParams = new URLSearchParams(path);

  const retrieveLangEnum = (language: string): languageEnum | undefined => {
    const languageMap: { [key: string]: languageEnum } = {
		fr: languageEnum.French,
		en: languageEnum.English,
    };
    return languageMap[language] || undefined;
  }

  const retrieveTypeEnum = (type: string): eventTypeEnum | undefined => {
    const typeMap: { [key: string]: eventTypeEnum } = {
		questions: eventTypeEnum.Support,
		entretien: eventTypeEnum.Service,
		relation: eventTypeEnum.Relation,
		introduction: eventTypeEnum.Introduction,
		portal: eventTypeEnum.Portal
    };
    return typeMap[type] || undefined;
  }

  useEffect(() => {
    const languageParam = urlParams.get('lang');
    const typeParam = urlParams.get('type');

    if (languageParam) {
      setLanguage(retrieveLangEnum(languageParam));
    }

    if (typeParam) {
      setEventType(retrieveTypeEnum(typeParam));
    }
  }, [urlParams]);

  const handleEventType = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setEventType(event.target.value as eventTypeEnum);
  }

  const handleLanguage = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setLanguage(event.target.value as languageEnum);
  };

  return (
    <div className="App">
      <LandingPage
        handleEventType={handleEventType}
        handleLanguage={handleLanguage}
        eventType={eventType}
        language={language}
        path={path}
      />
    </div>
  );
}

export default App;

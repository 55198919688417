import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Grid, Button } from '@mui/material';
import { eventTypeEnum, languageEnum, TimeData } from '../types';
import moment from 'moment';
import { groupBy, sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';

const GridContainer = styled(Grid)({
	margin: 'auto',
	maxWidth: '500px',
	marginBottom: '50px',
});

const GridHeader = styled(Grid)({
	marginBottom: '30px',
});

const HeaderTitle = styled(Typography)({
	fontWeight: 'bold',
	marginBottom: '10px',
});

const HeaderSubtitle = styled(Grid)({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'center',
	color: '#778899',
});

const TimeButton = styled(Button)({
	color: '#000',
	backgroundColor: '#FFF',
	maxWidth: '100px',
	'&:hover': {
		backgroundColor: '#FFF',
	},
});

const Separator = styled('span')({
	marginLeft: 4,
	marginRight: 4,
});

interface TimeBlocsGridProps {
  handleSelectTime: (timeData: TimeData, index: any) => void;
  selectedDate: any;
  activeTimeIndex?: number | null;
  appLanguage: string;
  eventType?: string;
}

const TimeBlocsGrid = ({
	handleSelectTime,
	selectedDate,
	activeTimeIndex,
	appLanguage,
	eventType,
}: TimeBlocsGridProps) => {
	const { t } = useTranslation();

	const filteredAndSortedList = () => {
		const groupByStartDateTime = groupBy(selectedDate, 'StartDateTime');
		const listToClean = Object.keys(groupByStartDateTime).map(item => groupByStartDateTime[item])

		const newList: any[] = []
		listToClean.forEach(element => {
			if (element.length > 1) {
				return newList.push(element[Math.floor(Math.random()*element.length)])
			}
			element.forEach(e => {
				newList.push(e)
			})
			return newList
		});

		return sortBy(newList, ['StartDateTime'])
	}

	let eventDuration;
	switch (eventType) {
		case eventTypeEnum.Support:
			eventDuration = '15';
			break;
		case eventTypeEnum.Portal:
			eventDuration = '75';
			break;
		default:
			eventDuration = '50';
	}

	const text = appLanguage === languageEnum.French
		? `${t('meeting')} ${eventDuration} ${t('minutes')}`
		: `${eventDuration} ${t('minutes')} ${t('appointment')}`;

	return (
		<>
			<GridHeader>
				<HeaderTitle>{t('pickTime')}</HeaderTitle>
				<HeaderSubtitle>
					<Typography>{text}</Typography>
					<Separator>/</Separator>
					<Typography>{t('timeZone')}</Typography>
				</HeaderSubtitle>
			</GridHeader>
			<GridContainer>
				<Grid
					container
					spacing={{ xs: 2, md: 2 }}
					columns={{ xs: 6, md: 16 }}
				>
					{filteredAndSortedList().map((date: any, index?: number) => {
						const timeData: TimeData = {
							durationInMinutes: date.DurationInMinutes,
							startDateTime: date.StartDateTime,
							endDateTime: date.EndDateTime,
							ownerId: date.OwnerId,
							appointmentEndDateTime: date.AppointmentEndDateTime,
							appointmentInMinutes: date.AppointmentInMinutes
						};

						return (
							<Grid key={index} item xs={2} sm={4} md={4}>
								<TimeButton
									variant="contained"
									onClick={() => handleSelectTime(timeData, index)}
									style={{
										backgroundColor: activeTimeIndex === index ? '#C9E3FB' : '',
									}}
								>
									{moment(date.StartDateTime).format('LT')}
								</TimeButton>
							</Grid>
						);
					})}
				</Grid>
			</GridContainer>
		</>
	);
};

export default TimeBlocsGrid;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import EN from './en.json';
import FR from './fr.json';

i18n.use(initReactI18next).init({
	resources: {
		en: {
			translation: EN,
		},
		fr: {
			translation: FR,
		},
	},
	fallbackLng: 'fr',
	compatibilityJSON: 'v3',
});

export default i18n;

import React from 'react';
import {
	FormControl,
	InputLabel,
	Input,
	FormControlLabel,
	Checkbox,
	Button,
	Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { FormInputs, UserData, eventTypeEnum } from '../types';
import { useForm, SubmitHandler } from 'react-hook-form';

const errorColor = '#d32f2f';

const MyFormGroup = styled('form')({
	display: 'flex',
	flexDirection: 'column',
	width: '300px',
	margin: '30px auto',
});

const MyFormControl = styled(FormControl)({
	marginTop: '20px',
});

const MyFormControlLabel = styled(FormControlLabel)({
	marginTop: '30px',
	fontSize: '10px',
});

const ErrorMessage = styled(Typography)({
	fontSize: 10,
	color: errorColor,
});

const SubmitButton = styled(Button)({
	marginTop: '30px',
});

interface FormProps {
  eventType?: string,
  selectedTime: string;
  selectedDate: any[];
  onSubmitEventForm: (userData: UserData) => void;
  submitEventLoading: boolean;
}

const Form = ({ eventType, onSubmitEventForm, submitEventLoading }: FormProps) => {
	const { t } = useTranslation();

	const onSubmit: SubmitHandler<FormInputs> = data => {
		const { firstName: firstname, lastName: lastname, phone, email } = data

		const userData = {
			firstname,
			lastname,
			email,
			phone,
		};
		onSubmitEventForm(userData);
	};

	const { register, formState: { errors }, handleSubmit } = useForm<FormInputs>();

	return (
		<MyFormGroup onSubmit={handleSubmit(onSubmit)}>
			<MyFormControl>
				<InputLabel htmlFor="firstname" error={!!errors.firstName}>{t('firstname')}</InputLabel>
				<Input
					id="firstname"
					type="text"
					aria-describedby="my-helper-text"
					error={!!errors.firstName}
					{...register('firstName', { required: true })}
				/>
				<ErrorMessage>{errors?.firstName?.message}</ErrorMessage>
			</MyFormControl>
			<MyFormControl>
				<InputLabel htmlFor="lastname" error={!!errors.lastName}>{t('lastname')}</InputLabel>
				<Input
					id="lastname"
					type="text"
					aria-describedby="my-helper-text"
					error={!!errors.lastName}
					{...register('lastName', { required: true })}
				/>
				{errors.lastName && <ErrorMessage>{errors.lastName.message}</ErrorMessage>}
			</MyFormControl>
			<MyFormControl>
				<InputLabel htmlFor="phone" error={!!errors.phone}>{t('phone')}</InputLabel>
				<Input
					id="phone"
					type="text"
					aria-describedby="my-helper-text"
					error={!!errors.phone}
					{...register('phone', { required: true, pattern: {
						value: /\d+/,
						message: 'This input is number only.'
					}})}
				/>
				{errors.phone && <ErrorMessage>{errors.phone.message}</ErrorMessage>}
			</MyFormControl>
			<MyFormControl>
				<InputLabel htmlFor="email" error={!!errors.email}>{t('email')}</InputLabel>
				<Input
					id="email"
					type="text"
					aria-describedby="my-helper-text"
					error={!!errors.email}
					{...register('email', { required: true, pattern: {
						value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
						message: 'invalid email address'
					}})}
				/>
				{errors.email && <ErrorMessage>{errors.email.message}</ErrorMessage>}
			</MyFormControl>
			<MyFormControlLabel
				control={<Checkbox sx={errors.checkbox ? {color: errorColor } : {color: ''} } {...register('checkbox', { required: true })}/>}
				label={`${t(eventType === eventTypeEnum.Relation ? 'emailConfirmationRelation' : 'emailConfirmation')}`}
				sx={errors.checkbox ? {color: errorColor } : {color: ''}}
			/>

			{ (eventType === eventTypeEnum.Relation || eventType === eventTypeEnum.Support) &&
			<MyFormControlLabel
				control={<Checkbox sx={errors.checkbox_supp ? {color: errorColor } : {color: ''} } {...register('checkbox_supp', { required: true })}/>}
				label={`${t('checkbox_supp')}`}
				sx={errors.checkbox_supp ? {color: errorColor } : {color: ''}}
			/> }

			<SubmitButton
				type="submit"
				variant="contained"
				color="primary"
				disabled={submitEventLoading}
			>
				{t('submit')}
			</SubmitButton>
		</MyFormGroup>
	);
};

export default Form;

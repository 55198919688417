import React, { useEffect, useState } from 'react';
import {
	Select,
	MenuItem,
	Button,
	FormControl,
	FormHelperText,
	Box,
	InputLabel,
	Typography,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';
import { styled } from '@mui/material/styles';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { groupBy } from 'lodash';
import axios from 'axios';
import {
	TimeData,
	UserData,
	FormValues,
	eventTypeEnum,
	languageEnum,
	relationEventTypeEnum,
} from '../types';
import reliefLogo from '../assets/relief_logo.png';
import moment from 'moment-timezone';
import EventPage from './EventPage';

const MySelect = styled(Select)({
	width: 300,
	marginBottom: 25,
});

const HeaderContainer = styled('div')({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	padding: 20,
	marginBottom: 20,
});

const MyInputLabel = styled(InputLabel)({
	height: 40,
	backgroundColor: '#FFFFF',
});

const MyForm = styled(Box)({
	width: '300px',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	margin: '20px auto',
});

const Submit = styled(Button)({
	marginTop: '20px',
});

const FormTitle = styled(Typography)({
	marginTop: '100px',
	fontSize: 18,
});

const Image = styled('img')({
	width: 100,
});

const FinalPage = styled('div')({
	marginTop: 150,
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'center',
	alignItems: 'center'
});

const SuccessTitle = styled(Typography)({
	fontSize: 18,
	fontWeight: 'bold',
	marginLeft: 5,
});

// const LanguageSwitch = styled('div')({
// 	borderStyle: 'solid',
// 	borderWidth: 1,
// 	borderColor: 'lightgrey',
// });

// const LanguageButton = styled(Button)({
// 	minWidth: 40,
// 	padding: 5,
// 	fontSize: 14,
// 	color: 'lightgrey',
// 	borderRadius: 0
// });

interface LandingPageProps {
		handleEventType: (event: any) => void;
		handleLanguage: (event: any) => void;
		eventType?: string;
		language?: string;
		path?: string;
}

const LandingPage = ({ handleEventType, handleLanguage, eventType, language, path }: LandingPageProps) => {
	const { t, i18n } = useTranslation();
	const [selectedTime, setSelectedTime] = useState('');
	const [selectedDate, setSelectedDate] = useState([]);
	const [availabilitiesData, setAvailabilitiesData] = useState({});
	const [timeData, setTimeData] = useState<TimeData | undefined>();
	const [activeDateIndex, setActiveDateIndex] = useState<number | null>();
	const [activeTimeIndex, setActiveTimeIndex] = useState<number | null>();
	const [isLoading, setIsLoading] = useState(true)
	const [errorMessage, setErrorMessage] = useState(false)
	const [meetingPresenceType, setMeetingPresenceType] = useState('');
	const [meetingVirtualType, setMeetingVirtualType] = useState('');

	const [isMeetingSuccessful, setIsMeetingSuccessful] = useState(false);
	const [submitEventLoading, setSubmitEventLoading] = useState(false);

	const [appLanguage, setAppLanguage] = useState(`${languageEnum.French}`);

	const eventTypeList = Object.values(eventTypeEnum);

	const languagesList =  Object.values(languageEnum);

	const handleChangeLanguage = (newLanguage: string) => {
		if (newLanguage === languageEnum.French) {
			setAppLanguage(languageEnum.French)
			i18n.changeLanguage('fr');
			moment.locale('fr')
		} else {
			setAppLanguage(languageEnum.English)
			i18n.changeLanguage('en');
			moment.locale('en')
		}
	};

	const handleSelectTime = (timeData: TimeData, index: any) => {
		setTimeData(timeData);
		setSelectedTime(timeData.startDateTime);
		setActiveTimeIndex(index);
	};

	const handleSelectDate = (dateValue: [], index: any) => {
		setActiveDateIndex(index);
		setSelectedDate(dateValue);
	};

	const groupByDate = (events: any) => {
		return groupBy(events, 'ZonedDate');
	};

	const handleSubmitPresenceType = (presenceType: string) => {
		setMeetingPresenceType(presenceType)
	}

	const handleSubmitVirtualType = (virtualType: string) => {
		setMeetingVirtualType(virtualType);
	}

	const {
		register,
		handleSubmit,
		control,
		reset,
		formState: { errors, isSubmitSuccessful },
	} = useForm<FormValues>();

	const onSubmit = () => {
		let presenceType;

		switch (eventType) {
		case eventTypeEnum.Relation:
		case eventTypeEnum.Portal:
			presenceType = meetingPresenceType;
			break;
		default:
			presenceType = relationEventTypeEnum.Virtual;
		}

		if (
			(eventType !== undefined && eventType !== '') &&
			(language !== undefined && language !== '') &&
			((eventType !== eventTypeEnum.Relation && eventType !== eventTypeEnum.Portal) || (presenceType !== undefined && presenceType !== '')) &&
			((eventType !== eventTypeEnum.Service && eventType !== eventTypeEnum.Introduction) || (meetingVirtualType !== undefined && meetingVirtualType !== ''))
		) {
			setIsLoading(true);
			setAvailabilitiesData([]);
			axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/event/availabilities?type=${eventType}&lang=${language}&mode=${presenceType}`).then(response => {
				if (response.data.length > 0) {
					const dataWithZonedDate = response.data.map((data:any)=> ({
						...data,
						ZonedDate: moment(data.StartDateTime).tz('America/New_York').format('YYYY-MM-DD')
					}));

					setAvailabilitiesData(groupByDate(dataWithZonedDate));
					setIsLoading(false)
				} else {
					setIsLoading(false)
					setErrorMessage(true)
				}
			}).catch(e => console.error(e.message))
		}
	};

	useEffect(() => {
		if (language === languageEnum.English) {
			handleChangeLanguage(languageEnum.English)
		} else {
			handleChangeLanguage(languageEnum.French)
		}

		if (eventType !== '' && language !== '' && path) {
			handleSubmit(onSubmit)()
		}
	}, [language, eventType])

	const handleReturn = () => {
		reset();
		setSelectedDate([]);
		setActiveDateIndex(null);
		setActiveTimeIndex(null);
		setSelectedTime('');
		handleEventType('');
		handleLanguage('');
		handleSubmitPresenceType('');
		handleSubmitVirtualType('');
		setErrorMessage(false);
	}

	const onSubmitEventForm = (userData: UserData) => {
		setSubmitEventLoading(true);

		const presenceType = eventType !== eventTypeEnum.Relation ? relationEventTypeEnum.Virtual : meetingPresenceType;

		const params = {
			event: {
				durationInMinutes: Number(timeData?.durationInMinutes),
				startDateTime: timeData?.startDateTime,
				endDateTime: timeData?.endDateTime,
				type: eventType,
				language: language,
				employeeSfId: timeData?.ownerId,
				mode: presenceType,
				virtualMode: meetingVirtualType,
				appointmentEndDateTime: timeData?.appointmentEndDateTime,
				appointmentInMinutes: timeData?.appointmentInMinutes
			},
			user: {
				firstName: userData.firstname,
				lastName: userData.lastname,
				email: userData.email,
				phone: userData.phone
			}
		};

		axios.post(
			process.env.REACT_APP_BASE_URL + '/api/v1/event/create',
			params
		).then(() => {
			setIsMeetingSuccessful(true);
			setSubmitEventLoading(false);
		}).catch(e =>{
			alert(t('timeframe.already.taken'));
			console.error(e.message);
			setSubmitEventLoading(false);
		})
	};

	const getTypeName = (typeName: string) => {
		if (typeName === eventTypeEnum.Relation) return 'Relation d\'aide'
		return typeName;
	}

	const disableButton = eventType !== '' && language !== '';
	// const languageButtonStyleFrench = appLanguage === languageEnum.French ? { backgroundColor: 'grey' } : {}
	// const languageButtonStyleEnglish = appLanguage === languageEnum.English ? { backgroundColor: 'grey' } : {}
	return (
		<div>
			<HeaderContainer>
				<Image src={reliefLogo} alt='relief_logo' />
				{/* <LanguageSwitch>
					<LanguageButton style={languageButtonStyleFrench} onClick={(e) => handleChangeLanguage(languageEnum.French)}>FR</LanguageButton>
					<LanguageButton style={languageButtonStyleEnglish} onClick={(e) => handleChangeLanguage(languageEnum.English)}>EN</LanguageButton>
				</LanguageSwitch> */}
			</HeaderContainer>
				{isMeetingSuccessful ? (
					<FinalPage>
						<CheckIcon color='success' />
						<SuccessTitle>{t('meetingSuccess')}</SuccessTitle>
					</FinalPage>
				) : (
					<>
						{!path && (
							<Box sx={{ textAlign: 'left', marginLeft: 20, cursor: 'pointer' }}>
								{isSubmitSuccessful && (
									<ArrowBackIcon onClick={handleReturn}/>
								)}
							</Box>
						)}
						{!isSubmitSuccessful ? (
							<form onSubmit={handleSubmit(onSubmit)}>
								<FormTitle>{t('selectOptions')}</FormTitle>
								<MyForm>
									<Controller
										control={control}
										name="eventType"
										render={({ field }) => (
											<FormControl error={Boolean(errors.eventType)}>
												<MyInputLabel id="eventType">{t('type')}</MyInputLabel>
												<MySelect
													labelId="eventType"
													{...field}
													{...register('eventType')}
													error={Boolean(errors.eventType)}
													label="Type"
													onChange={handleEventType}
													defaultValue={''}
												>
													{eventTypeList.map((value) => (
														<MenuItem key={value} value={value}>
															{getTypeName(value)}
														</MenuItem>
													))}
												</MySelect>
												<FormHelperText>{errors.eventType?.message}</FormHelperText>
											</FormControl>
										)}
									/>
									<Controller
										control={control}
										name="language"
										render={({ field }) => (
											<FormControl error={Boolean(errors.language)}>
												<MyInputLabel id="language">{t('language')}</MyInputLabel>
												<MySelect
													labelId="language"
													{...field}
													{...register('language')}
													label="Language"
													onChange={handleLanguage}
													defaultValue={''}
												>
													{languagesList.map((value) => (
														<MenuItem key={value} value={value}>
															{value}
														</MenuItem>
													))}
												</MySelect>
												<FormHelperText>{errors.language?.message}</FormHelperText>
											</FormControl>
										)}
									/>
									<Submit
										type="submit"
										color="primary"
										variant="contained"
										disabled={!disableButton}
									>
										{t('next')}
									</Submit>
								</MyForm>
							</form>
						) : (
							<EventPage
								selectedDate={selectedDate}
								activeDateIndex={activeDateIndex}
								handleSelectDate={handleSelectDate}
								availabilitiesData={availabilitiesData}
								errorMessage={errorMessage}
								isLoading={isLoading}
								handleSelectTime={handleSelectTime}
								activeTimeIndex={activeTimeIndex}
								appLanguage={appLanguage}
								eventType={eventType}
								onSubmitEventForm={onSubmitEventForm}
								selectedTime={selectedTime}
								handleSubmitPresenceType={handleSubmitPresenceType}
								handleSubmitVirtualType={handleSubmitVirtualType}
								meetingPresenceType={meetingPresenceType}
								meetingVirtualType={meetingVirtualType}
								onSubmit={onSubmit}
								submitEventLoading={submitEventLoading}
							/>
						)}
					</>
				)}
		</div>
	);
};

export default LandingPage;
